var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "6",
      "sm": "12"
    }
  }, [_c('b-form', [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "6",
      "sm": "12"
    }
  }, [_c('b-form-group', [_c('label', {
    attrs: {
      "for": "nama_kontak"
    }
  }, [_vm._v("Nama Lengkap")]), _c('b-form-input', {
    attrs: {
      "id": "nama_kontak",
      "type": "text",
      "placeholder": "Nama Lengkap"
    },
    model: {
      value: _vm.markers[0].nama_kontak,
      callback: function callback($$v) {
        _vm.$set(_vm.markers[0], "nama_kontak", $$v);
      },
      expression: "markers[0].nama_kontak"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "sm": "12"
    }
  }, [_c('b-form-group', [_c('label', {
    attrs: {
      "for": "nomor_kontak"
    }
  }, [_vm._v("No. Telepon (Aktif)")]), _c('b-form-input', {
    attrs: {
      "id": "nomor_kontak",
      "type": "text",
      "placeholder": "Nomor Telepon"
    },
    model: {
      value: _vm.markers[0].nomor_kontak,
      callback: function callback($$v) {
        _vm.$set(_vm.markers[0], "nomor_kontak", $$v);
      },
      expression: "markers[0].nomor_kontak"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "sm": "12"
    }
  }, [_c('b-form-group', [_c('label', {
    attrs: {
      "for": "label_alamat"
    }
  }, [_vm._v("Label Alamat")]), _c('b-form-input', {
    attrs: {
      "id": "label_alamat",
      "type": "text",
      "placeholder": "Rumah, Kantor, Apartemen"
    },
    model: {
      value: _vm.markers[0].label_alamat,
      callback: function callback($$v) {
        _vm.$set(_vm.markers[0], "label_alamat", $$v);
      },
      expression: "markers[0].label_alamat"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "sm": "12"
    }
  }, [_c('b-form-group', [_c('label', {
    attrs: {
      "for": "jenis"
    }
  }, [_vm._v("Jenis Alamat")]), _c('b-form-radio', {
    attrs: {
      "name": "jenis_alamat",
      "value": "utama",
      "disabled": ""
    },
    model: {
      value: _vm.markers[0].jenis_alamat,
      callback: function callback($$v) {
        _vm.$set(_vm.markers[0], "jenis_alamat", $$v);
      },
      expression: "markers[0].jenis_alamat"
    }
  }, [_vm._v(" Utama ")]), _c('b-form-radio', {
    attrs: {
      "name": "jenis_alamat",
      "value": "toko"
    },
    model: {
      value: _vm.markers[0].jenis_alamat,
      callback: function callback($$v) {
        _vm.$set(_vm.markers[0], "jenis_alamat", $$v);
      },
      expression: "markers[0].jenis_alamat"
    }
  }, [_vm._v(" Toko ")]), _c('b-form-radio', {
    attrs: {
      "name": "jenis_alamat",
      "value": "retur",
      "disabled": ""
    },
    model: {
      value: _vm.markers[0].jenis_alamat,
      callback: function callback($$v) {
        _vm.$set(_vm.markers[0], "jenis_alamat", $$v);
      },
      expression: "markers[0].jenis_alamat"
    }
  }, [_vm._v(" Retur ")])], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "sm": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Provinsi",
      "label-for": "v-provinsi"
    }
  }, [_c('v-select', {
    attrs: {
      "reduce": function reduce(option) {
        return option.value;
      },
      "label": "text",
      "options": _vm.optprovinsi
    },
    on: {
      "input": function input($event) {
        return _vm.getkabkota();
      }
    },
    model: {
      value: _vm.markers[0].provinsi_id,
      callback: function callback($$v) {
        _vm.$set(_vm.markers[0], "provinsi_id", $$v);
      },
      expression: "markers[0].provinsi_id"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "sm": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Kabupaten/Kota",
      "label-for": "v-kabko"
    }
  }, [_c('v-select', {
    attrs: {
      "reduce": function reduce(option) {
        return option.value;
      },
      "label": "text",
      "options": _vm.optkabko
    },
    on: {
      "input": function input($event) {
        return _vm.getkecamatan();
      }
    },
    model: {
      value: _vm.markers[0].kab_kota_id,
      callback: function callback($$v) {
        _vm.$set(_vm.markers[0], "kab_kota_id", $$v);
      },
      expression: "markers[0].kab_kota_id"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "sm": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Kecamatan",
      "label-for": "v-kecamatan"
    }
  }, [_c('v-select', {
    attrs: {
      "reduce": function reduce(option) {
        return option.value;
      },
      "label": "text",
      "options": _vm.optkec
    },
    on: {
      "input": function input($event) {
        return _vm.getkelurahan();
      }
    },
    model: {
      value: _vm.markers[0].kecamatan_id,
      callback: function callback($$v) {
        _vm.$set(_vm.markers[0], "kecamatan_id", $$v);
      },
      expression: "markers[0].kecamatan_id"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "sm": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Kelurahan/Desa",
      "label-for": "v-kel"
    }
  }, [_c('v-select', {
    attrs: {
      "reduce": function reduce(option) {
        return option.value;
      },
      "label": "text",
      "options": _vm.optkel
    },
    model: {
      value: _vm.markers[0].desa_id,
      callback: function callback($$v) {
        _vm.$set(_vm.markers[0], "desa_id", $$v);
      },
      expression: "markers[0].desa_id"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12",
      "sm": "12"
    }
  }, [_c('b-form-group', [_c('label', {
    attrs: {
      "for": "alamat"
    }
  }, [_vm._v("Alamat")]), _c('b-form-textarea', {
    attrs: {
      "id": "alamat",
      "placeholder": "Nama Jalan, Gang, Gedung, No Rumah"
    },
    model: {
      value: _vm.markers[0].alamat,
      callback: function callback($$v) {
        _vm.$set(_vm.markers[0], "alamat", $$v);
      },
      expression: "markers[0].alamat"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12",
      "sm": "12"
    }
  }, [_c('b-form-group', [_c('label', {
    attrs: {
      "for": "reward"
    }
  }, [_vm._v("Catatan")]), _c('b-form-textarea', {
    attrs: {
      "id": "deskripsi",
      "placeholder": "Catatan lainnya, Contoh (Nomor blok, Titip ke satpam rumah, Patokan)"
    },
    model: {
      value: _vm.markers[0].catatan,
      callback: function callback($$v) {
        _vm.$set(_vm.markers[0], "catatan", $$v);
      },
      expression: "markers[0].catatan"
    }
  })], 1)], 1)], 1)], 1), _c('b-button', {
    staticClass: "mr-1",
    attrs: {
      "size": "sm",
      "variant": "primary"
    },
    on: {
      "click": _vm.submit
    }
  }, [_vm._v(" Simpan ")])], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "sm": "12"
    }
  }, [_c('b-button', {
    staticClass: "mr-1",
    attrs: {
      "size": "sm",
      "variant": "primary",
      "block": ""
    },
    on: {
      "click": function click($event) {
        return _vm.getLocation();
      }
    }
  }, [_vm._v(" Perbarui Lokasi ")]), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "6",
      "sm": "12"
    }
  }, [_c('b-form-group', [_c('label', {
    attrs: {
      "for": "positionla"
    }
  }, [_vm._v("Latitude")]), _c('b-form-input', {
    attrs: {
      "id": "positionla",
      "type": "text",
      "readonly": ""
    },
    model: {
      value: _vm.markers[0].position.lat,
      callback: function callback($$v) {
        _vm.$set(_vm.markers[0].position, "lat", $$v);
      },
      expression: "markers[0].position.lat"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "sm": "12"
    }
  }, [_c('b-form-group', [_c('label', {
    attrs: {
      "for": "positionlo"
    }
  }, [_vm._v("Longitude")]), _c('b-form-input', {
    attrs: {
      "id": "positionlo",
      "type": "text",
      "readonly": ""
    },
    model: {
      value: _vm.markers[0].position.lng,
      callback: function callback($$v) {
        _vm.$set(_vm.markers[0].position, "lng", $$v);
      },
      expression: "markers[0].position.lng"
    }
  })], 1)], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "12",
      "sm": "12"
    }
  }, [_c('b-overlay', {
    attrs: {
      "show": _vm.loadingmap
    }
  }, [_vm.mode == 1 ? _c('l-map', {
    staticStyle: {
      "height": "500px",
      "width": "100%"
    },
    attrs: {
      "zoom": _vm.zoom,
      "options": _vm.mapOptions,
      "center": _vm.center,
      "min-zoom": _vm.minZoom,
      "max-zoom": _vm.maxZoom
    },
    on: {
      "update:zoom": function updateZoom($event) {
        _vm.zoom = $event;
      }
    }
  }, [_c('l-control-layers', {
    attrs: {
      "position": _vm.layersPosition,
      "collapsed": false,
      "sort-layers": true
    }
  }), _vm._l(_vm.tileProviders, function (tileProvider) {
    return _c('l-tile-layer', {
      key: tileProvider.name,
      attrs: {
        "name": tileProvider.name,
        "visible": tileProvider.visible,
        "url": tileProvider.url,
        "attribution": tileProvider.attribution,
        "token": _vm.token,
        "layer-type": "base"
      }
    });
  }), _c('l-control-zoom', {
    attrs: {
      "position": _vm.zoomPosition
    }
  }), _c('l-control-attribution', {
    attrs: {
      "position": _vm.attributionPosition,
      "prefix": _vm.attributionPrefix
    }
  }), _c('l-control-scale', {
    attrs: {
      "imperial": _vm.imperial
    }
  }), _vm._l(_vm.markers, function (marker) {
    return _vm.mode == 1 ? _c('l-marker', {
      key: marker.id,
      attrs: {
        "visible": marker.visible,
        "draggable": marker.draggable,
        "lat-lng": marker.position,
        "icon": marker.icon
      },
      on: {
        "update:latLng": function updateLatLng($event) {
          return _vm.$set(marker, "position", $event);
        },
        "update:lat-lng": function updateLatLng($event) {
          return _vm.$set(marker, "position", $event);
        }
      }
    }, [_c('l-popup', {
      attrs: {
        "content": marker.tooltip
      }
    }), _c('l-tooltip', {
      attrs: {
        "content": marker.tooltip
      }
    })], 1) : _vm._e();
  })], 2) : _vm._e()], 1)], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }